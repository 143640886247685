@keyframes shake_hand {
  3%,
  15% {
    transform: rotate(-12deg);
  }

  9% {
    transform: rotate(12deg);
  }

  18% {
    transform: rotate(0deg);
  }
}

.container {
  width: 36px;
  height: 36px;
  background-image: url('./assets/hand.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.animated {
  animation: shake_hand 3s linear 2s infinite;
}
