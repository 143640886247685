.container {
  position: fixed;
  z-index: 10;
  right: 12px;
  bottom: 24px;
  margin: auto;
  display: flex;
  align-items: center;
}

.hand {
  margin-right: 6px;
}
