.container {
  width: 100%;
  height: 100%;
}

.iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}

.close {
  position: absolute;
  top: 14px;
  right: 14px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
