.container {
  position: fixed;
  z-index: 2;
  right: var(--unit_3);
  bottom: calc(var(--unit_3) + 34px + var(--unit_3));
  width: var(--unit_12);
  height: var(--unit_12);
  background: linear-gradient(135deg, var(--control-main-primary-default), var(--control-main-primary-hovered));
  background-color: var(--control-main-primary-default);
  border-radius: 50%;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.1),
    inset 0 -2px 5px rgba(0, 0, 0, 0.05);
}

.onboarding {
  position: absolute;
  top: 0;
  left: var(--unit_6);
}

.chat-control {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.chat-control__loading {
  pointer-events: none;
}

.chat-logo {
  width: var(--unit_6);
  height: var(--unit_6);
  background-image: url('./assets/chatEntry.png');
  background-size: contain;
}
