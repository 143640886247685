.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 100dvh;
  justify-content: space-between;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: var(--unit_10);
}

.footer {
  padding: var(--unit_3) var(--unit_4);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.close {
  position: absolute;
  top: var(--unit_4);
  right: var(--unit_4);
}
